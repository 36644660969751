<template>
  <v-card>
    <v-card-title>Nueva alerta, estudio {{ idEstudio }}</v-card-title>
    <v-divider></v-divider>

    <div class="pa-3">
      <v-form ref="form">
        <v-text-field
          label="Resumen alerta"
          filled
          dense
          hide-details="auto"
          :rules="[rules.req]"
          v-model="alerta.resumen"
        ></v-text-field>
        <br />
        <v-textarea
          label="Texto alerta"
          filled
          dense
          hide-details="auto"
          :rules="[rules.req]"
          v-model="alerta.texto"
        ></v-textarea>
        <br />

        <v-autocomplete
          :items="usuarios"
          v-model="alerta.usuarios"
          filled
          :rules="[(v) => v.length > 0 || 'Tienes que seleccionar al menos 1']"
          multiple
          small-chips
          deletable-chips
          label="Alertar a"
        >
        </v-autocomplete>

        <v-row :key="keyHelper">
          <v-col cols="12">
            <v-subheader>Fecha y hora</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col>
            <v-date-picker
              landscape
              no-title
              full-width
              :min="
                [
                  new Date().getFullYear(),
                  ('00' + (new Date().getMonth() + 1)).slice(-2),
                  ('00' + new Date().getDate()).slice(-2),
                ].join('-')
              "
              v-model="alerta.date"
            ></v-date-picker>
          </v-col>
          <v-col>
            <v-time-picker
              full-width
              no-title
              format="24hr"
              :allowed-minutes="[0, 15, 30, 45]"
              scrollable
              ref="hourPicker"
              v-model="alerta.hour"
            ></v-time-picker>
          </v-col>
          <v-col cols="12" class="d-flex" v-if="alerta.date && alerta.hour">
            {{ parseDate(timestamp, false, true, true) }}
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click.stop="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <br />
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn text color="error" @click.stop="$emit('close')"
            >Cancelar</v-btn
          >
          <v-btn
            class="ml-2"
            color="primary"
            @click.stop="
              $refs.form.validate() && timestamp > 0 ? crearAlerta() : null
            "
            >Crear</v-btn
          >
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";
import { parseDate } from "@/utils/index.js";

export default {
  props: {
    idEstudio: String | Number,
  },
  computed: {
    timestamp() {
      if (!this.alerta.date || !this.alerta.hour) return null;
      return new Date(`${this.alerta.date} ${this.alerta.hour}:00`).getTime();
    },
  },
  data() {
    return {
      rules: {
        req,
      },
      alerta: {},
      usuarios: [],
      keyHelper: 0,
    };
  },
  methods: {
    parseDate,
    clear() {
      this.$delete(this.alerta, "hour");
      this.$delete(this.alerta, "date");
      this.keyHelper++;
      // this.$refs.hourPicker.$emit('click:hour')
    },
    async crearAlerta() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/cronograma/nuevaAlerta.php`,
        method: "POST",
        data: {
          idEstudio: this.idEstudio,
          texto: this.alerta.texto,
          resumen: this.alerta.resumen,
          timestamp: this.timestamp,
          usuarios: this.alerta.usuarios
        },
      });
      this.$emit("reload");
      this.$emit("close");
    },
    async getUsuarios() {
      const { data: usuarios } = await axios(
        `${process.env.VUE_APP_API_URL}/opciones/usuarios.php`
      );
      this.usuarios = usuarios.map((u) => ({
        text: `${u.idUsuario} - ${u.nombre} - ${u.email}`,
        value: u.idUsuario,
      }));

      // this.alerta.usuarios = [( this.usuarios.find(x => x.idUsuario == this.$store.getters.getTokenPayload.idUsuario) )]

      this.$set(this.alerta, 'usuarios', [this.$store.getters.getTokenPayload.idUsuario])

    },
  },
  mounted() {
    this.getUsuarios();
  },
};
</script>
